import '@fontsource/ubuntu/400.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'
import './src/sass/style.scss'
import './src/templates/Pricing/PricingPage.scss'
import 'Shared/sass/style.scss'

import {
  MauticOnRouteChange,
  MauticOnWebLoad,
} from 'Shared/mautic/EasyRedmine/ERMauticScripts'
import { initDynamicRS } from 'Shared/utils/dynamicRS'
import {
  checkHash,
  isBrowser,
  scroller,
} from 'Shared/utils/PuxAnchorLink/puxAnchorUtils'
import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  if (document.querySelector(`.pux-notfound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
  initDynamicRS()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onInitialClientRender = () => {
  MauticOnWebLoad()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRouteUpdate = ({ location }) => {
  MauticOnRouteChange()

  let windowHash
  let offset = 0
  let duration = 800

  if (isBrowser) {
    window.gatsby_scroll_offset = offset
    window.gatsby_scroll_duration = duration
    windowHash = window.gatsby_scroll_hash
  }

  windowHash ? scroller(windowHash, offset) : checkHash(location, offset)

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}
