// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-templates-article-article-page-tsx": () => import("./../../../src/templates/Article/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-article-page-tsx" */),
  "component---src-templates-case-study-case-study-detail-tsx": () => import("./../../../src/templates/CaseStudy/CaseStudyDetail.tsx" /* webpackChunkName: "component---src-templates-case-study-case-study-detail-tsx" */),
  "component---src-templates-category-category-page-tsx": () => import("./../../../src/templates/Category/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-category-page-tsx" */),
  "component---src-templates-change-log-change-log-page-tsx": () => import("./../../../src/templates/ChangeLog/ChangeLogPage.tsx" /* webpackChunkName: "component---src-templates-change-log-change-log-page-tsx" */),
  "component---src-templates-easy-software-page-tsx": () => import("./../../../src/templates/easySoftwarePage.tsx" /* webpackChunkName: "component---src-templates-easy-software-page-tsx" */),
  "component---src-templates-faq-faq-detail-tsx": () => import("./../../../src/templates/Faq/FaqDetail.tsx" /* webpackChunkName: "component---src-templates-faq-faq-detail-tsx" */),
  "component---src-templates-function-function-detail-tsx": () => import("./../../../src/templates/Function/FunctionDetail.tsx" /* webpackChunkName: "component---src-templates-function-function-detail-tsx" */),
  "component---src-templates-home-page-pux-home-page-tsx": () => import("./../../../src/templates/HomePage/PuxHomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-pux-home-page-tsx" */),
  "component---src-templates-knowledge-base-knowledge-base-article-tsx": () => import("./../../../src/templates/KnowledgeBase/KnowledgeBaseArticle.tsx" /* webpackChunkName: "component---src-templates-knowledge-base-knowledge-base-article-tsx" */),
  "component---src-templates-knowledge-base-knowledge-base-category-tsx": () => import("./../../../src/templates/KnowledgeBase/KnowledgeBaseCategory.tsx" /* webpackChunkName: "component---src-templates-knowledge-base-knowledge-base-category-tsx" */),
  "component---src-templates-knowledge-base-knowledge-base-tsx": () => import("./../../../src/templates/KnowledgeBase/KnowledgeBase.tsx" /* webpackChunkName: "component---src-templates-knowledge-base-knowledge-base-tsx" */),
  "component---src-templates-landing-landing-page-tsx": () => import("./../../../src/templates/Landing/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-landing-page-tsx" */),
  "component---src-templates-news-news-detail-tsx": () => import("./../../../src/templates/News/NewsDetail.tsx" /* webpackChunkName: "component---src-templates-news-news-detail-tsx" */),
  "component---src-templates-pricing-pricing-page-tsx": () => import("./../../../src/templates/Pricing/PricingPage.tsx" /* webpackChunkName: "component---src-templates-pricing-pricing-page-tsx" */),
  "component---src-templates-training-center-training-center-page-tsx": () => import("./../../../src/templates/TrainingCenter/TrainingCenterPage.tsx" /* webpackChunkName: "component---src-templates-training-center-training-center-page-tsx" */)
}

